import React from "react";
import Layout from "layout";
import { Link } from "gatsby";
import Hero from "common/hero";
import Seo from "common/seo";
import { Row, Col } from "react-grid-system";
import { TypeA } from "common/callouts";
import heroImageRef from "images/sun/hero.webp";
import icon1 from "images/icons/icon-impact.png";
import icon2 from "images/icons/icon-axos-shield.png";
import icon3 from "images/icons/icon-sun-product.png";
import img1 from "images/callouts/resources2x.webp";
import img2 from "images/callouts/cout-footer-daily-skin.webp";
import img3 from "images/callouts/cout-footer-journal.webp";

import "./sun.scss";

function BodyPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-sun"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Sun"
        pageDescription="Learn about Eucerin Sun and its formulation, offering your patients beyond braod-spectrum protection"
      />
      <div className="inner-body-content">
        <section className="inner-centered-container colored-boxes">
          <h2>How can you help patients adopt daily sunscreen use?</h2>
          <Row>
            <Col sm={12} md={3}>
              <ColoredBox
                imgRef={icon1}
                imgAlt="Disruptive Thinking"
                headText="Disruptive Thinking"
                bodyText="IMPACTS OF SUNLIGHT"
                boxLink="/sun/impactsofsunlight"
                headBgColor="#6ACAD5"
              />
            </Col>
            <Col sm={12} md={3}>
              <ColoredBox
                imgRef={icon2}
                imgAlt="FREE RADICAL DEFENSE"
                headText="FREE RADICAL DEFENSE"
                bodyText="5 AOX Shield™"
                boxLink="/sun/freeradicaldefense"
                headBgColor="#003E7E"
              />
            </Col>
            <Col sm={12} md={3}>
              <ColoredBox
                imgRef={icon3}
                imgAlt="Product Family"
                headText="Product Family"
                bodyText="Eucerin<sup>®</sup> Sun"
                boxLink="/sun/eucerinsunproducts"
                headBgColor="#E07810"
              />
            </Col>
          </Row>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img1}
                  imgAlt="Resources for Sun Care"
                  caption="Find resources to support your
                  sun care recommendations"
                  buttonText="Browse resources"
                  buttonUrl="/resources"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img2}
                  imgAlt="Explore Body Care"
                  caption="Clinically proven therapeutic
                  solutions for daily skin care"
                  buttonText="Explore Body Care"
                  buttonUrl="/body"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img3}
                  imgAlt="Discover JAAD articles"
                  caption="Discover how perspectives in sun protection are evolving"
                  buttonText="VIEW JOURNAL ARTICLES"
                  buttonUrl="https://www.jaad.org/issue/S0190-9622(22)X0002-9"
                  imgStyles={{ height: 125 }}
                  isExternalLink={true}
                  targetOpen="_blank"
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}
export default BodyPage;

const MyHero = () => (
  <Hero className="inner-hero" bgImagePath={heroImageRef}>
    <div className="inner-hero-content">
      <h1>
        Embrace sun protection <nobr>that goes</nobr> beyond{" "}
        <nobr>broad-spectrum</nobr>
      </h1>
      <p className="hero-reg-text">
        Therapeutic goals are evolving from sun protection to sun damage
        prevention for everyone under the sun. Help your patients understand the
        issues and provide strategies they will actually use.
      </p>
    </div>
  </Hero>
);

const ColoredBox = ({
  imgRef,
  imgAlt,
  headText,
  bodyText,
  boxLink,
  headBgColor,
}) => (
  <div className="colored-box">
    <img src={imgRef} alt={imgAlt || ""} />
    <h3 style={{ backgroundColor: headBgColor }}>{headText}</h3>
    <Link to={boxLink}>
      {" "}
      <p className="box-text" dangerouslySetInnerHTML={{ __html: bodyText }} />
    </Link>
  </div>
);
